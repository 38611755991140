<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('pages.warehouse.inventoryTransferListing')" :onSearch="onSearch">               
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.startDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.startDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">   
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.agent')}} </label>
                                    <div class="col-sm-9">
                                        <multiselect :options="agents"
                                                     v-model="searchInfo.selectedAgents"
                                                     :loading="isLoadingAgents"
                                                     :searchable="true"
                                                     :close-on-select="true"
                                                     :clear-on-select="false"
                                                     :multiple="true"
                                                     :placeholder="$t('common.typetosearch')"
                                                     :noOptionsText="$t('common.theListIsEmpty')"
                                                     :reset-after="false"
                                                     :local-search="false"
                                                     @search-change="asyncFindAgency"
                                                     :internal-search="false"
                                                     id="ajax"
                                                     label="name"
                                                     track-by="id">
                                        </multiselect>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.endDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.endDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.status')}} </label>
                                    <div class="col-sm-9">
                                        <multiselect :options="allDistributeStatus"
                                                     v-model="searchInfo.selectedStatusList"
                                                     :searchable="false"
                                                     :close-on-select="false"
                                                     :clear-on-select="false"
                                                     :limit="10"
                                                     :multiple="true"
                                                     :placeholder="$t('common.picksome')"
                                                     :reset-after="false"
                                                     label="label"
                                                     track-by="key">
                                        </multiselect>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>                       
                    </CForm>
                </template>
                <template v-slot:list>
                    <div class="row">
                        <div class="col-md-2">
                            <b><label>Tổng số tiền:</label></b>
                        </div>
                        <div class="col-md-6">
                            <p><b>{{$func.formatCurrency(totalAmount)}}</b></p>
                        </div>
                        <!--<div class="col-md-2" v-if="allTransferInventory != null && allTransferInventory.length > 0">
                            <CButton color="primary" variant="outline" square size="sm" @click="onOutputReport">
                                Xuất báo cáo
                            </CButton>
                                                      
                            <form id="reportForm" method="post" action="" target="_blank">
                                <input name="agentIds" id="agentIds" value="" type="hidden" />
                                <input name="statusList" id="statusList" value="" type="hidden" />
                                <input name="startDate" id="startDate" value="" type="hidden" />
                                <input name="endDate" id="endDate" value="" type="hidden" />
                            </form>
                        </div>-->
                    </div>
                    <CDataTable :items="allTransferInventory"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoading"
                                pagination
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                :items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100] }">
                        <template #footer v-if="allTransferInventory != null && allTransferInventory.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="8">
                                        <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{allTransferInventory == null ? 0: allTransferInventory.length}}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>
                        <template #numberOfProducts_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.numberOfProducts)" />
                            </td>
                        </template>

                        <template #amount_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.amount)" />
                            </td>
                        </template>
                        <template #debtPoints_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.debtPoints)" />
                            </td>
                        </template>
                        <template #createdDate_text="{item}">
                            <td class="py-2">
                                <label v-text="formatDate(item.createdDate)" />
                            </td>
                        </template>
                        <template #Distribute_status="{item}">
                            <td class="py-2">
                                <button type="button" class="btn" :class="distributeStatusCss(item.status)">{{$t(`pages.orders.orderStatus_${item.status}`)}}</button>
                            </td>
                        </template>
                        <template #InventoryTransfer_InOut="{item, index}">
                            <td class="py-2">        
                                <CButton color="primary" variant="outline" square size="sm" @click="onViewOrderDetail(item, index)">
                                    {{ $t('common.detail') }}
                                </CButton>
                                <!--<CButton v-if="item.status <= 1" color="primary" variant="outline" square size="sm" style="margin-left:5px"
                                         @click="onRejectDistribution(item, index)">
                                    {{ $t('common.rejected') }}
                                </CButton>-->
                                <CButton v-if="item.status == 2" color="primary" variant="outline" style="margin-left:5px" square size="sm" @click="onPaymentConfirmation(item, index)">
                                    {{ $t('common.paymentConfirmation') }}
                                </CButton>                                
                            </td>
                        </template>
                    </CDataTable>
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>

<script>
    const apiUrl = process.env.VUE_APP_APIURL;
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import ListForm from '@/components/ListForm.vue'
    import OrderStatus from '@/helpers/OrderStatus'

    import { mapState, mapActions } from 'vuex'

    export default ({
        name: 'InventoryTransferList',
        data() {
            return {               
                isLoadingProducts: false,
                itemsPerPage: 10,
                agents: [],
                isLoadingAgents: false,
                allDistributeStatus: [],               
                fields: [
                    { key: 'id', label: i18n.t('pages.orders.orderNumber') },
                    { key: 'createdBy', label: i18n.t('pages.orders.buyer') },
                    { key: 'buyingAgentName', label: i18n.t('pages.warehouse.buyingAgent') },
                    { key: 'numberOfProducts_text', label: i18n.t('pages.product.numberOfProducts') },
                    { key: 'amount_text', label: i18n.t('common.amount') },
                    //{ key: 'debtPoints_text', label: i18n.t('common.debtPoints') },                                                                                                                 
                    { key: 'Distribute_status', label: i18n.t('common.status'), sorter: false, filter: false },
                    { key: 'createdDate_text', label: i18n.t('pages.warehouse.createdDate')  },                      
                    { key: 'InventoryTransfer_InOut', label: i18n.t('common.action'),  sorter: false, filter: false },
                ],               
            };
        },     
        components: {
            Confirmation,
            ListForm
        },
        computed: {         
            ...mapState('transferInventory', ['searchInfo', 'isLoading', 'allTransferInventory', 'totalPages', 'pageIndex', 'totalAmount']),
        },
        methods: {
            ...mapActions('transferInventory', ['searchTransferInventory', 'outputReport']),
                  
            distributeStatusCss(iStatus) {

              
                if (iStatus == OrderStatus.Approved)
                    return "btn-primary";
                if (iStatus == OrderStatus.Shipped)
                    return "btn-info";
                if (iStatus == OrderStatus.Delivered)
                    return "btn-success";
                if (iStatus == OrderStatus.Canceled)
                    return "btn-warning";
                if (iStatus == OrderStatus.Returned)
                    return "btn-dark";

               
                return "";
            },
            formatDate(date) {
              
                if(date === undefined)
                    return '';                
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');               
            },
            onSearch() {
                this.searchTransferInventory();                
            },

            async onRejectDistribution(item) {
                this.$refs.confirmation.show(i18n.t('pages.warehouse.confirmReject'), async () => {
                    var retVal = await this.$store.dispatch("receivedInventory/rejectDistibutionBySeller", item.id);
                    if (retVal == true) {
                        this.onSearch();
                    }
                });
            },
           
            async onPaymentConfirmation(item) {                
                var retVal = await this.$store.dispatch("transferInventory/paymentConfirmation", item.id);                 
                 if (retVal == true) {                     
                     this.onSearch();
                 }                 
             },            
             async asyncFindAgency(query, id) {
                
                 if (query.length > 1) {
                     this.isLoadingAgents = true;
                     var result = await this.$agentService.searchByName(query);
                     this.isLoadingAgents = false;
                     this.agents = result;
                 }                 
            },
            onItemsPerPageChange(item) {
                localStorage.setItem('agentOrdersItemsPerPage', item)
            },
            async onViewOrderDetail(item) {
                this.$router.push('/warehouses/order-detail/' + item.id);
            },
            async onOutputReport() {
                var form = document.getElementById('reportForm');
                form.action = apiUrl + '/reports/exportorderb2b';

                let _statusList = '', _agentIds = '';
                let statusIds = this.searchInfo.selectedStatusList.map(item => item.key);
                if (statusIds.length > 0)
                    _statusList = statusIds.join();

                let agentIds = this.searchInfo.selectedAgents.map(item => item.id);
                if (agentIds.length > 0)
                    _agentIds = agentIds.join();
                
                document.getElementById('statusList').value = _statusList;               
                document.getElementById('agentIds').value = _agentIds;
                document.getElementById('startDate').value = this.searchInfo.startDate != null ? this.searchInfo.startDate : '';
                document.getElementById('endDate').value = this.searchInfo.endDate != null ? this.searchInfo.endDate : '';
                                             
                form.submit();
                return;                
            },            
        },
        mounted() {
            
        },
        created() {
            this.searchInfo.selectedStatusList = [{ key: OrderStatus.Pending, label: i18n.t('common.pending') }, { key: OrderStatus.Approved, label: i18n.t('pages.orders.orderStatus_2') }];
            this.allDistributeStatus = this.$distributionService.getAllDistributeStatusList();

            this.onSearch();

            let agentOrderItemsPerPage = parseInt(localStorage.getItem('agentOrdersItemsPerPage'));
            if (isNaN(agentOrderItemsPerPage))
                agentOrderItemsPerPage = 10;
            this.itemsPerPage = agentOrderItemsPerPage;           
        }
    })
</script>